#agora {
  // max-width: 1440px;
  height: 100vh;
  padding: 36px 40px;
  margin: 0 auto;
  color: #fff;
  background-color: #111518;
  overflow: hidden;

  .agora-header {
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    width: 1200px;

    .title {
      font-size: 25px;
    }
  }

  .agora-container {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .table {
      width: 49%;
    }
    .current {
      width: 49%;

      .sub-title {
        margin: 20px 0;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
      }

      .operation {
        display: flex;
        align-items: center;
        margin-top: 10px;

        button {
          margin-right: 10px;
        }
      }
    }
  }
}

@primary-color: #1890ff;