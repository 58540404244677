#airdrop {
  
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .tab-item  {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      background: ~"var(--label-bg)";
      border-radius: 8px;
      border: 1px solid ~"var(--label-border)";
      font-size: 14px;
      font-family: Inter;
      color: ~"var(--label-text)";
      line-height: 38px;
      transition: all 0.5s;

      &:hover {
        background: ~"var(--label-hover-bg)";
        border: 1px solid #6e6e6e;
      }

      &.ant-tag-checkable-checked {
        background: ~"var(--label-active-bg)";
        border-color: #d0d0d0;
        color: ~"var(--black-text)";
      }
    }
  }

  .slogan {
    max-width: 540px;
    margin: 20px auto;
    font-size: 60px;
    font-family: Inter;
    font-weight: bold;
    color: #ffffff;
    line-height: 72px;
    background: ~"var(--gradient-bg)";
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &.type-1 {
      background: ~"var(--gradient-text-purple)";
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .desc {
    width: 850px;
    margin: 0 auto;
    font-size: 16px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 20px;
    opacity: 0.4;
  }

  .container {

    .empty {

      button {
        display: block;
        margin: 40px auto;
        width: 180px;
        height: 40px;
        background: #7269EF;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        color: #fff;
      }
    }

    .mission {

      img {
        margin: 40px;
      }
      .mission-list {
        display: flex;
        align-items: center;
        justify-content: center;

        .mission-card {
          width: 240px;
          margin: 0 6px;
          padding: 16px 0;
          background: ~"var(--list-item-bg)";
          border-radius: 16px;

          .icon, .name {
            font-size: 24px;
            color: ~"var(--list-item-text)";
            opacity: 0.5;
          }

          .name {
            margin: 4px 0 10px;
            font-size: 18px;
            font-weight: bold;
          }

          .go, .completed {
            width: 120px;
            height: 36px;
            margin: 0 auto;
            background-color: #7269EF;
            color: #fff;
            text-align: center;
            line-height: 36px;
            border-radius: 8px;
            cursor: pointer;
          }

          .completed {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ~"var(--card-bottom-bg)";
            color: ~"var(--list-item-text)";
          }

          &.complete {
            .icon, .name {
              opacity: 1;
            }
          }
        }
      }
      .claim-btn {
        margin: 20px auto;
        width: 240px;
        height: 40px;
        background: #7269EF;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        color: #fff;

        &.disabled {
          background: ~"var(--card-bottom-bg)";
          cursor: not-allowed;
          color: ~"var(--select-arrow)";
        }
      }
    }

    .claimed {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px auto;

      .egg-card {
        position: relative;
        width: 240px;
        height: 300px;
        margin: 0 6px;
        background: ~"var(--list-item-bg)";
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 100%;
        }

        .name, .count {
          position: absolute;
          left: 50%;
          bottom: 40px;
          transform: translateX(-50%);
          font-size: 18px;
          font-weight: bold;
          color: ~"var(--list-item-text)";
        }
        .count {
          bottom: 10px;
          height: 34px;
          font-size: 28px;
          line-height: 34px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  #airdrop {
    .desc {
      width: 100%;
    }
    .empty {
      img {
        width: 100%;
      }
    }
    .container {

      .mission {
        .mission-list {
          flex-direction: column;

          .mission-card {
            width: 80%;
            margin-bottom: 15px;
          }
        }
      }

      .claimed {
        flex-wrap: wrap;

        .egg-card {
          width: 45%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@primary-color: #1890ff;