.space-container {
	max-width: 1440px;
	min-height: 100vh;
	margin: 0 auto;
	padding: 24px 40px;
	background-color: ~"var(--main-bg)";
	color: ~"var(--list-item-text)";

	.space-info-container {
		width: 100%;
		height: calc(100vh - 143px);
		display: flex;

		.left-container {
			width: 330px;
			height: 100%;
			display: flex;
			flex-direction: column;

			.play-record-button{
				cursor: pointer;
				font-weight: bold;
				padding: 7px 0;
				margin:12px 0;
				text-align: center;
				background:rgba(114, 105, 239, 1);
				border-radius: 8px;
			}

			.space-info-chat-space {
				display: flex;
				flex-direction: column;
				flex-shrink: 0;
				height: 200px;
				padding: 16px;
				background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
				text-align: left;
				border-radius: 16px;
			
				&.scheduled {
					background: linear-gradient(225deg, #9881a3 0%, #4689a2 100%);
				}
				&.ended {
					background: linear-gradient(225deg, #3c2765 0%, #191c66 100%);
				}

				.audio-control-btn-container{
					display: flex;
					.audio-control-btn{
						cursor: pointer;
						color:#fff;
						opacity: 0.8;
						display: flex;
						padding:0 7px;
						align-items: center;
						height: 32px;
						background: rgba(17,21,24,0.3);
						border-radius: 7px;
						margin-right: 5px;
					}
				}
				.title {
					height: 42px;
					margin-top: 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-size: 18px;
					font-family: Inter;
					font-weight: bold;
					color: #ffffff;
					line-height: 21px;
				}
			
				.state {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					font-size: 14px;
					font-family: Inter;
					font-weight: bold;
					color: #ffffff;
					line-height: 17px;
			
					.hostname {
						font-size: 14px;
						font-family: Inter;
						color: #ffffff;
						line-height: 17px;
						cursor: pointer;
			
						&:hover {
							text-decoration: underline;
						}
					}

					.out-link {
						margin-left: auto;
						cursor: pointer;
					}
				}
			
				.labels {
					margin-top: auto;
					display: flex;
					justify-content: space-between;
				}
				.links{
					display: flex;
					.btn-tag {
						background-color: rgba(0,0,0,0.3);
						width: 28px;
						height: 28px;
						border-radius: 8px;
						padding: 0;
						text-align: center;
						border: 0;
						font-size: 28px;
						color: #d0d0d0;
						font-family: Inter;
						cursor: pointer;
						margin-left: 8px;
						margin-right: 0;
	
						img {
							width: 100%;
						}
					}
				}
			}

			.speaker {
				flex: 1;
				height: calc(100% - 212px);
				margin-top: 12px;
				border-radius: 16px;
				background-color: ~"var(--host-info-bg)";
				overflow: auto;
				&.record-button{
					height:calc(100% - 272px);
				}
				.ant-collapse {
					border-radius: 16px;
					border:none;
					background-color: transparent;
					.ant-collapse-content{
						border:none;
						background-color: ~"var(--host-info-bg)";
						color:~"var(--white-text)";
					}

					& > .ant-collapse-item {
						margin-bottom: 16px;
						border-radius: 8px;
						background-color: ~"var(--host-info-bg)";
						border:none;
						& > .ant-collapse-header {
							color:~"var(--white-text)" !important;
							font-size: 14px;
							font-family: Inter;
							font-weight: bold;
				}}}
				
				.ant-collapse-content > .ant-collapse-content-box{
					padding:0;
				}
				.ant-collapse.ant-collapse-icon-position-start{
					height:100%;
					overflow:hidden;
				}
				.ant-collapse-item.ant-collapse-item-active.speaker-panel{
					height:calc(100% - 138px);
					.ant-collapse-content.ant-collapse-content-active{
						height:100%;
						overflow: auto;
					}
				}
				.ant-collapse-header{
					flex-direction: row-reverse;
				}
				.ai-summary-lang-label{
					padding:8px;
					display: flex;
					align-items: center;
					.summary-key{
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						padding:4px 8px;
						width:40px;
						margin-right: 8px;
						background-color: var(--right-text-background);
						border-radius: 8px;
					}

				}
				.ai-summary{
					padding:8px;
					max-height: 200px;
					overflow: auto;
				}
				.icon{
					margin-right: 4px;
				}
			}
		}

		.middle-container {
			position: relative;
			flex: 1;
			height: 100%;
			margin: 0 12px;
			border-radius: 16px;
			background-color: ~"var(--host-info-bg)";

			.red-packet-test {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}

		.right-container {
			position: relative;
			width: 330px;
			height: 100%;
			border-radius: 16px;
			background-color: ~"var(--host-info-bg)";
		}

		.record-container{
			flex: 1 1 calc(100vh - 346px);
			background-color: ~"var(--host-info-bg)";
			border-radius: 16px;
			margin-left:4px;
		}

		.record-header{
			font-size: 14px;
			font-family: Inter;
			color: ~"var(--white-text)";
			text-align: left;
		}
		.widget-header {
			margin: 16px;
			font-size: 14px;
			font-family: Inter;
			font-weight: bold;
			color: ~"var(--white-text)";
			text-align: left;

			.icon {
				margin-right: 4px;
			}
		}

		.placeholder-img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: transparent;
			
			.login-btn {
				width: 188px;
				height: 36px;
				margin-top: 20px;
				background: ~"var(--card-bottom-bg)";
				border-radius: 8px;
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--white-text)";
				line-height: 36px;
				text-align: center;
				cursor: pointer;
			}
		}

		#chat_widget_speaker_container,
		#chat_widget_chat_container,
		#chat_widget_ask_container {
			height: calc(100% - 54px);
			border-radius: 0 0 16px 16px;
			overflow: auto;
		}
  }

	.scheduled-space-info-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 920px;
		margin: 46px auto;

		.space-box {
			max-width: 450px;
			border-radius: 16px;
			overflow: hidden;
		}

		.faq {
			width: 450px;
			height: 358px;
			background-color: ~'var(--list-item-bg)';
			border-radius: 16px;
			overflow: hidden;

			.widget-header {
				margin: 16px;
				font-size: 14px;
				font-family: Inter;
				font-weight: bold;
				color: ~"var(--white-text)";
				text-align: left;
	
				.icon {
					margin-right: 4px;
				}
			}
		}

		.space-info-chat-space {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-shrink: 0;
			height: 290px;
			padding: 16px;
			background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
			text-align: left;
		
			&.scheduled {
				background: linear-gradient(225deg, #9881a3 0%, #4689a2 100%);
			}
			&.ended {
				background: linear-gradient(225deg, #3c2765 0%, #191c66 100%);
			}

		
			.title {
				height: 42px;
				margin-top: 15px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-size: 18px;
				font-family: Inter;
				font-weight: bold;
				color: #ffffff;
				line-height: 21px;
				text-align: center;
			}

			.avatar {
				border-radius: 50%;
				border: 2px solid #fff;
				margin: 15px 0 8px;
				overflow: hidden;
			}
		
			.state {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-size: 14px;
				font-family: Inter;
				font-weight: bold;
				color: #ffffff;
				line-height: 17px;
				opacity: 0.5;
		
				.hostname {
					font-size: 14px;
					font-family: Inter;
					color: #ffffff;
					line-height: 17px;
					cursor: pointer;
		
					&:hover {
						text-decoration: underline;
					}
				}

				.out-link {
					margin-left: auto;
					cursor: pointer;
					color: #fff;
				}
			}

			.time {
				display: flex;
				align-items: center;
				margin-top: auto;
				font-size: 12px;
				font-weight: bold;
				color: #fff;
				opacity: 0.6;
				
				img {
					margin-right: 3px;
					margin-bottom: 2px;
				}
			}
		
			.labels {
				margin-top: auto;
			}
			.btn-tag {
				float: right;
				width: 28px;
				height: 28px;
				border-radius: 8px;
				padding: 0;
				text-align: center;
				border: 0;
				font-size: 14px;
				font-family: Inter;
				cursor: pointer;
				margin-left: 8px;
				margin-right: 0;

				img {
					width: 100%;
				}
			}
		}

		.count-down {
			// height: 200px;
			background-color: ~'var(--list-item-bg)';
			overflow: hidden;
			font-family: Inter;

			.tips {
				margin-top: 32px;
				text-align: center;
				color: ~'var(--list-item-text)';
				opacity: 0.6;
			}

			.countBox {
				display: flex;
				align-items: center;
				flex-direction: row-reverse;
				justify-content: center;
				margin-top: 12px;

				.countBoxItem {
					display: flex;
					flex-direction: column-reverse;
					align-items: center;
					margin: 0 20px;

					&:first-child {
						display: none;
					}

					.label {
						font-size: 12px;
						color: ~'var(--list-item-text)';
						opacity: 0.6;
					}
					.count {
						height: 44px;
						line-height: 44px;
						font-size: 36px;
						font-weight: bold;
						color: ~'var(--list-item-text)' !important;
						background-color: transparent !important;
					}
				}
			}

			.buttons {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 16px;

				.button {
					width: 133px;
					height: 36px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					font-weight: bold;
					color: ~'var(--label-active-bg)';
					background-color: ~'var(--card-bottom-bg)';
					border-radius: 8px;
					cursor: pointer;

					.icon {
						display: inline-block;
						width: 28px;
						padding-top: 5px;
						text-align: center;

						&.collect {
							transform: scale(0.9);
						}
					}
				}
			}
		}
	}

	.scheduled-space-list {

		.subtitle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin: 20px 0;

			.title {
				font-size: 18px;
				font-weight: bold;
			}
		}

		.space-list {
			display: flex;
			align-items: center;
		}

		.explore-more {
      width: 129px;
      height: 40px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid ~"var(--label-border)";
      color: ~"var(--white-text)";

      &:hover {
        color: ~"var(--list-item-text)";
      }
    }
		.pager {
			width: 40px;
		height: 40px;
				padding: 7px;
				margin-left: 12px;
		background: transparent;
		border-radius: 8px;
		border: 1px solid ~"var(--label-border)";
		color: ~"var(--white-text)";

      &:hover {
        color: ~"var(--list-item-text)";
      }

      &.right {
        transform: rotateY(180deg);
      }
    }
  }
}

.enter-modal {
  width: 440px;
  background: var(--login-bg);
  border-radius: 16px;
  .enter-image {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .enter-title {
    display: flex;
    justify-content: center;
    width: 320px;
    height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: var(--white-text);
    line-height: 25px;
    margin: 0 auto;
  }
  .enter-button {
    display: block;
    margin: 20px auto;
    height: 36px;
    background: #7269ef;
    border-radius: 8px;
	border:none;
  }
  .enter-text {
    text-align: center;
    display: block;
    margin: 20px auto;
    font-size: 14px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: var(--white-text);
    opacity: 0.6;
    line-height: 17px;
  }
}

@media (max-width: 700px) {
	.space-container {
		max-width: 100vw !important;
		padding: 20px;
		
		.space-info-container {
			flex-direction: column;
			height: auto;

			.left-container,
			.middle-container,
			.right-container {
				width: calc(100vw - 40px);
				margin: 0 0 10px;
				min-height: 300px;
			}
		}
		.scheduled-space-info-container {
			flex-direction: column;
			.faq {
				width: 100%;
				margin-top: 20px;
			}
			.count-down .buttons {
				.button {
					width: 30%;
					.label {
						display: none;
					}
				}
			}
		}
	}
}

@primary-color: #1890ff;