@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --main-bg: #111518;
  --plyr-color-main: #b9b4fe;
  --plyr-audio-controls-background: var(--host-info-bg);
  --plyr-control-radius: 16px;
  --plyr-audio-control-color: var(--label-text);
  --label-border: #323232;
  --label-bg: #141414;
  --label-text: rgba(255, 255, 255, 0.8);
  --label-active-bg: #d0d0d0;
  --gradient-bg: linear-gradient(
    135deg,
    #7ce6b2 0%,
    #92c0f3 31%,
    #dccffe 77%,
    #fbfaff 100%
  );
  --card-bg: #1e262c;
  --card-bottom-bg: rgba(208, 208, 208, 0.1);
  --card-button-text: #d0d0d0;
  --black-text: #000;
  --label-hover-bg: #1d1d1d;
  --white-text: rgba(255, 255, 255, 0.6);
  --list-item-bg: #1e262c;
  --list-item-border: #111518;
  --list-item-text: #fff;
  --select-arrow: rgba(255, 255, 255, 0.3);
  --popover-bg: rgba(30, 38, 44, 1);
  --popover-text: rgba(255, 255, 255, 0.85);
  --login-bg: #242e36;
}

.App,
html {
  width: 100vw;
  text-align: center;
  background-color: ~"var(--main-bg)";

  .ant-divider-plain.ant-divider-with-text {
    color: var(--popover-text);
  }
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid var(--white-text);
  }
  .ant-list-empty-text {
    display: none;
  }

  .ant-modal-wrap {
    z-index: 10000;
  }

  .ant-tooltip {
    z-index: 99999;
  }

  .custom-notify {
    border-radius: 12px;
    background: ~"var(--list-item-bg)";
    border: 1px solid ~"var(--host-info-divider)";

    &.long-message {
      width: 500px;
    }

    .ant-notification-notice-with-icon {
      position: relative;
      font-family: Inter;

      .ant-notification-notice-icon {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .ant-notification-notice-message,
      .ant-notification-notice-description {
        margin-left: 76px;
        text-align: left;
        color: ~"var(--list-item-text)";
      }

      .ant-notification-notice-description {
        opacity: 0.5;
      }
    }

    .ant-notification-notice-close .anticon-close {
      color: ~"var(--list-item-text)";
      opacity: 0.5;
    }
  }

  .ant-modal {
    .anticon-close {
      color: ~"var(--list-item-text)";
    }

    .ant-modal-body {
      padding: 16px;
    }

    .ant-modal-content {
      background: ~"var(--login-bg)";
      border-radius: 16px;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body.modal-open {
  overflow: hidden;
}
.plyr__controls {
  margin-top: 12px;
  .plyr__controls__item:first-child {
    background: #b9b4fe !important;
  }
}
@keyframes loading-360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@primary-color: #1890ff;