.list-loader-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;


  .list-loader-card {
    display: inline-block;
    padding: 8px;

    &-inner {
      display: flex;
      flex-direction: column;
      height: 220px;
      padding: 12px 16px;
      background: ~'var(--card-bg)';
      border-radius: 16px;
      text-align: left;

      .state .state {
        height: 17px;
      }
      .title .title {
        margin: 15px 0;
      }
      .desc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;

        .text .text {
          width: 200px;
          height: 25px;
        }
        .avatar .ant-skeleton-avatar {
          width: 60px;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }

  .list-loader-host-card {
    display: inline-block;
    padding: 8px;

    &-inner {
      display: flex;
      flex-direction: column;
      height: 250px;
      background: ~'var(--card-bg)';
      border-radius: 16px;
      text-align: left;
      overflow: hidden;

      .top {
        display: flex;
        justify-content: space-between;
        height: 92px;
        padding: 12px 16px 8px;
        background-color: ~"var(--right-text-background)";
        margin-bottom: 16px;

        .name .name {
          width: 200px;
          height: 42px;
        }
        .avatar .ant-skeleton-avatar {
          width: 60px;
          height: 60px;
          line-height: 60px;
        }
      }
      .bottom {
        padding: 0 16px 16px;

        .text .text {
          width: 120px;
          min-width: 120px;
          height: 17px;

          &.long {
            width: 230px;
          }
        }

        .title .title {
          height: 42px;
          margin: 0 0 6px;
        }

        .time .time {
          margin-top: 10px;
        }
      }
    }
  }

  .list-loader-list {
    width: 100%;
    padding: 8px 30px;

    .ant-skeleton-header {
      vertical-align: middle;
      
      .ant-skeleton-avatar-lg {
        width: 72px;
        height: 72px;
        line-height: 72px;
        margin-right: 20px;
      }
    }
  }

  .ant-skeleton-input {
    border-radius: 4px;
  }
  .ant-skeleton-button {
    border-radius: 8px;
  }
}
@primary-color: #1890ff;