.home-container {
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 24px 40px;
  background-color: ~"var(--main-bg)";
  // overflow: hidden;
  color: #fff;

  .profile-container {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .profile-card {
      width: 312px;
      border-radius: 16px;
      background-color: ~"var(--host-info-bg)";

      .profile-card-top {
        position: relative;
        display: flex;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;

        .info {
          width: calc(100% - 76px);
          margin-left: 16px;

          .name,
          .account {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 100%;
            font-size: 18px;
            font-family: Roboto;
            color: ~"var(--list-item-text)";

            .anticon {
              display: none;
            }

            &:hover {
              .anticon {
                display: inline-block;
              }
            }
          }
          .account {
            display: block;
            font-size: 14px;
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            .anticon {
              display: inline-block;
              margin-right: 3px;
            }
          }
          .name .value {
            max-width: 100%;
            margin-right: 4px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .host-avatar {
        position: relative;
        width: 60px;
        height: 60px;
        border: 2px solid #fff;
        border-radius: 50%;

        &.live {
          border: 2px solid rgb(55, 59, 255);
        }

        .cover {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 50%;

          .anticon {
            font-size: 18px;
          }
        }

        img {
          height: 100%;
          border-radius: 50%;
        }

        &:hover {
          .cover {
            display: flex;
          }
        }
      }

      .follow-btn {
        width: 100%;
        border-bottom: 1px solid ~"var(--host-info-divider)";

        .btn {
          width: 280px;
          height: 36px;
          margin: 8px 16px 20px;
          background: #7269ef;
          border-radius: 18px;
          font-size: 14px;
          font-family: Inter;
          font-weight: bold;
          color: #ffffff;
          line-height: 22px;

          &.followed {
            display: flex;
            justify-content: center;

            span {
              display: flex;
              align-items: center;
            }
            .icon {
              margin-right: 8px;
            }
          }

          &.following {
            background: ~"var(--card-bottom-bg)";
            color: ~"var(--card-button-text)";
          }
          &.unfollow {
            background: #d64e6d;
          }
        }
      }

      .info-count {
        padding: 18px 16px;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0;

          .label,
          .value {
            font-size: 14px;
            font-family: Inter;
            color: ~"var(--list-item-text)";
            line-height: 17px;
          }
          .label {
            opacity: 0.5;
          }
        }
      }

      .live-space {
        padding: 6px 12px;
        border-bottom: 1px solid ~"var(--host-info-divider)";

        .card-bottom {
          padding-top: 12px;
          background-color: ~"var(--right-text-background)";
        }
      }
    }

    .profile-calendar {
      width: 312px;
      border-radius: 16px;
      background-color: ~"var(--host-info-bg)";
      margin-top: 12px;

      .title {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        color: ~"var(--list-item-text)";
        opacity: 0.6;

        .icon {
          padding-right: 5px;
        }
      }

      .calendar {
        padding: 0 6px;

        .ant-picker-calendar,
        .ant-picker-calendar .ant-picker-panel {
          background-color: transparent;
          border: 0;
        }

        .ant-picker-cell .ant-picker-cell-inner {
          display: flex;
          flex-direction: column;
          width: 30px;
          height: 30px;
          margin: 0 auto;
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected
          .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-start
          .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-end
          .ant-picker-cell-inner {
          color: ~"var(--list-item-text)";
          background: #7269ef;
          border-radius: 50%;
        }

        .ant-picker-calendar-date-value {
          color: ~"var(--select-arrow)";
        }

        .ant-picker-cell-in-view .ant-picker-calendar-date-value {
          color: ~"var(--list-item-text)";
        }

        .ant-picker-cell-in-view.ant-picker-cell-today
          .ant-picker-cell-inner::before {
          border: 1px solid #7269ef;
          border-radius: 50%;
        }

        .custom-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;

          .month {
            font-size: 18px;
            color: ~"var(--list-item-text)";
          }

          .buttons {
            display: flex;
            width: 40px;
            align-items: center;
            justify-content: space-between;

            .anticon {
              cursor: pointer;
              color: ~"var(--list-item-text)";
              opacity: 0.6;
            }
          }
        }
      }
      .events {
        padding: 6px 16px 16px;
        border-top: 1px solid ~"var(--right-text-background)";

        .event-card {
          position: relative;
          border-radius: 6px;
          background-color: ~"var(--card-bottom-bg)";
          margin-top: 10px;

          .event-type {
            position: absolute;
            top: 4px;
            right: 8px;

            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-left: 6px;
            }

            &.Joined {
              color: #94ef69;
              .dot {
                background-color: #94ef69;
              }
            }
            &.red {
              color: #ff1a83;
              .dot {
                background-color: #ff1a83;
              }
            }
            &.Favored {
              color: #f4a301;
              .dot {
                background-color: #f4a301;
              }
            }
          }

          .event-space {
            display: flex;
            padding: 20px 8px 12px;
            text-align: left;
            font-size: 12px;
            color: ~"var(--list-item-text)";
            cursor: pointer;

            .avatar {
              flex-shrink: 0;
              width: 27px;
              height: 27px;
              border-radius: 50%;
              margin-right: 8px;
              background-color: #6e6e6e;
              border: 1px solid #fff;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .state {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .title {
              padding: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .date-events {
        position: relative;
        top: -3px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6px;
        overflow: hidden;

        .dot {
          display: inline-block;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin-left: 2px;

          &:first-child {
            margin: 0;
          }

          &.Joined {
            background-color: #94ef69;
          }
          &.red {
            background-color: #ff1a83;
          }
          &.Favored {
            background-color: #f4a301;
          }
        }
      }
    }

    .profile-space-list {
      width: calc(100% - 342px);
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;

      .ant-spin-container,
      .ant-spin-nested-loading {
        width: 100%;
      }

      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: ~"var(--list-item-text)";
        opacity: 0.6;

        img {
          margin-top: 80px;
          margin-bottom: 30px;
        }
      }

      .filter {
        width: 100%;
        .filter-top {
          display: flex;
          justify-content: space-between;

          .state {
            display: flex;
            align-items: center;
          }

          .state-tag {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            background: ~"var(--label-bg)";
            border-radius: 8px;
            border: 1px solid ~"var(--label-border)";
            font-size: 14px;
            font-family: Inter;
            color: ~"var(--label-text)";
            line-height: 38px;
            transition: all 0.5s;

            .anticon-fire {
              padding-right: 3px;
            }

            &:hover {
              background: ~"var(--label-hover-bg)";
              border: 1px solid #6e6e6e;
            }

            &.ant-tag-checkable-checked {
              background: ~"var(--label-active-bg)";
              border-color: #d0d0d0;
              color: ~"var(--black-text)";

              &.live {
                border: 0;
                color: #fff;
                background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
              }
            }
            img {
              margin-left: 3px;
            }
          }
          .filter-select {
            .ant-select-selector {
              width: 130px;
              height: 40px;
              background: ~"var(--label-bg)";
              border-radius: 0px 8px 8px 0px;
              color: ~"var(--label-text)";
              border: 1px solid ~"var(--label-border)";

              .ant-select-selection-item {
                line-height: 38px;
              }
              &:hover {
                background: ~"var(--label-hover-bg)";
                border: 1px solid #6e6e6e;
              }
            }
            .ant-select-arrow {
              color: ~"var(--select-arrow)";
            }

            &:first-child {
              .ant-select-selector {
                border-radius: 8px 8px 8px 8px;
              }
            }
          }
          .ant-select-single.ant-select-open .ant-select-selection-item {
            color: ~"var(--label-text)";
          }
          .lang-layout {
            display: flex;
            width: 250px;

            .layout {
              display: flex;
              margin-left: auto;
            }
            .layout-tag {
              padding: 0 8px;
              line-height: 35px;
              transition: all 0.5s;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
        .filter-bottom {
          .label-tag {
            position: relative;
            height: 25px;
            padding: 0 15px;
            margin-bottom: 10px;
            background: ~"var(--label-bg)";
            border-radius: 13px;
            border: 1px solid ~"var(--label-border)";
            font-size: 14px;
            font-family: Inter;
            color: ~"var(--label-text)";
            line-height: 23px;
            transition: all 0.5s;

            &:hover {
              background: ~"var(--label-hover-bg)";
              border: 1px solid #6e6e6e;
            }

            &.ant-tag-checkable-checked {
              background: ~"var(--label-active-bg)";
              border-color: #d0d0d0;
              color: ~"var(--black-text)";
            }

            .cancel-label {
              position: absolute;
              top: -7px;
              right: -7px;
              width: 14px;
              height: 14px;
              cursor: pointer;
            }
          }
        }
      }

      .ant-form-inline .ant-form-item {
        width: 200px;
      }

      .space-list {
        width: 100%;
        margin-top: 20px;
      }

      .search-sub-title {
        margin: 20px 0;
        text-align: left;
        font-size: 18px;
        font-family: Inter;
        font-weight: bold;
        color: ~"var(--list-item-text)";
        line-height: 24px;
      }

      .load-more-btn {
        width: 112px;
        height: 40px;
        margin-top: 10px;
        background: ~"var(--list-item-border)";
        border-radius: 8px;
        border: 1px solid ~"var(--label-border)";
        font-size: 14px;
        font-family: Inter;
        color: ~"var(--list-item-text)";
        line-height: 18px;
      }
    }
  }
}

.nick-avatar-modal {
  font-family: Inter;

  .ant-modal-close-x {
    color: ~"var(--list-item-text)";
  }

  .title {
    font-size: 18px;
    color: ~"var(--list-item-text)";
  }
  .nick-input {
    margin: 10px 0;
    color: ~"var(--list-item-text)";
  }
  .btn .confirm {
    width: 112px;
    height: 40px;
    margin-top: 10px;
    background: ~"var(--gradient-bg)";
    border-radius: 8px;
    border: 1px solid ~"var(--label-border)";
    font-size: 14px;
    font-family: Inter;
    color: ~"var(--black-text)";
    line-height: 18px;
  }
  .crop-avatar-wrap {
    display: flex;
    margin-top: 20px;

    .content {
      width: 350px;
      height: 350px;
      margin-right: 1rem;
      // background-color: ;
    }

    .preview {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .img-preview {
        width: 170px;
        height: 170px;
        overflow: hidden;
        margin-bottom: 10px;
      }
      .radius {
        border-radius: 50%;
      }
    }
  }
}

@media (max-width: 700px) {
  .home-container {
    width: 100vw;
    padding: 20px;

    .profile-container {
      flex-direction: column;
      align-items: center;

      .profile-card,
      .host-space-list,
      .profile-calendar,
      .profile-space-list {
        width: calc(100vw - 40px);
      }
      .profile-space-list {
        margin-left: 0;
        margin-top: 15px;

        .filter .filter-top {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .state-tag {
            padding: 0 10px;

            &:last-child {
              margin: 0;
            }
          }
          .lang-layout {
            display: none;
          }
        }
      }
    }
  }
}

@primary-color: #1890ff;