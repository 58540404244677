.space-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  background-color: ~'var(--card-bg)';
  border-radius: 16px;
  overflow: hidden;

  .card-top {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 152px;
    padding: 12px 16px 7px;
    background: linear-gradient(225deg, #995DFB 0%, #373BFF 100%);
    cursor: pointer;

    &.scheduled {
      background: linear-gradient(225deg, #9881A3 0%, #4689A2 100%);
    }
    &.ended {
      background: linear-gradient(225deg, #3C2765 0%, #191C66 100%);
    }
  }

  .title {
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-family: Inter;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 21px;
  }

  .state {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Inter;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 17px;

    .hostname {
      font-size: 14px;
      font-family: Inter;
      color: #FFFFFF;
      line-height: 17px;
      opacity: 0.6;
    }
    .state-name {
      opacity: 0.6;
    }

    .featured-icon {
      margin-left: auto;
      color: red;
    }
  }

  .labels {
    margin-top: auto;
  }
  .host-avatar {
    position: absolute;
    bottom: -16px;
    right: 16px;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;

    &.live {
      border: 2px solid rgb(55,59,255);
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      height: 100%;
      border-radius: 50%;
    }

    .follow-state {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      left: 41px;
      top: 41px;
      border: 1px solid #1e262c;
      transform: none;
      border-radius: 50%;
      background-color: #7269ef;
      font-size: 11px;
      transition: all 0.5s;

      &.followed {
        background-color: #fff;
        color: #000;
      }
    }
  }
  .card-bottom {
    display: flex;
    align-items: center;
    padding: 28px 16px 12px;

    .count, .time {
      font-size: 12px;
      font-family: Inter;
      font-weight: bold;
      color: ~'var(--white-text)';
      line-height: 15px;
      margin-left: 20px;
    }
    .time {
      margin-left: 0;
    }
    .buttons {
      margin-left: auto;
      .btn {
        height: 36px;
        padding: 0 10px;
        background: ~'var(--card-bottom-bg)';
        border-radius: 8px;
        font-size: 12px;
        font-family: Inter;
        font-weight: bold;
        color: ~'var(--card-button-text)';
        line-height: 15px;
        border: 0;

        &:hover {
          background: rgba(208,208,208,0.3);
        }
      }
    }
    
    .btn-tag {
      float: right;
      width: 28px;
      height: 28px;
      background-color: ~"var(--card-bottom-bg)";
      border-radius: 8px;
      padding: 0;
      text-align: center;
      border: 0;
      font-size: 28px;
      color: ~'var(--card-button-text)';
      font-family: Inter;
      cursor: pointer;
      margin-left: 8px;
      margin-right: 0;

      &:last-child {
        margin: 0;
      }
    }
  }
  .users {
    width: 75px;
    height: 45px;
    flex-shrink: 0;
    padding: 5px 0;
    .user {
      display: inline-block;
      width: 25px;
    }
    .user-avatar {
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid #fff;

      &:nth-child(1) {
        z-index: 9;
      }
      &:nth-child(2) {
        z-index: 8;
      }
      &:nth-child(3) {
        z-index: 7;
      }
      &:nth-child(4) {
        z-index: 6;
      }
    }
  }
}
.list-item {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  height: 92px;
  background: ~'var(--list-item-bg)';
  border: 1px solid ~'var(--list-item-border)';
  margin-bottom: 2px !important;
  cursor: pointer;

  &:hover {
    background: rgba(114,105,239,0.1);
  }
  .avatar-wrap {
    width: 85px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .featured-icon {
      position: absolute;
      top: 10px;
      right: 0;
      color: red;
    }
  }
  .host-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    padding: 2px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50%;

    &.live {
      border: 2px solid rgb(55,59,255);
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      height: 100%;
      border-radius: 50%;
    }
  }
  .center {
    margin: 0 25px;
    width: calc(100% - 480px);
    text-align: left;

    &.short {
      width: calc(100% - 100px);
    }

    .state {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .state-info {
        font-size: 14px;
        font-family: Inter;
        font-weight: bold;
        color: ~'var(--list-item-text)';
        line-height: 17px;
        opacity: 0.6;
      }
      .hostname {
        font-size: 14px;
        font-family: Inter;
        color: ~'var(--list-item-text)';
        line-height: 17px;
        opacity: 0.6;
      }

      .labels {
        margin-left: 10px;
      }
    }
    .title {
      width: 100%;
      font-size: 18px;
      font-family: Inter;
      font-weight: bold;
      color: ~'var(--list-item-text)';
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .users {
    width: 100px;
    padding: 5px 0;
    .user {
      display: inline-block;
      width: 25px;
    }
    .user-avatar {
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid #fff;

      &:nth-child(1) {
        z-index: 9;
      }
      &:nth-child(2) {
        z-index: 8;
      }
      &:nth-child(3) {
        z-index: 7;
      }
      &:nth-child(4) {
        z-index: 6;
      }
    }
  }
  .count, .time {
    margin-left: 10px;
    font-size: 14px;
    font-family: Inter;
    font-weight: bold;
    color: ~'var(--white-text)';
  }
  .buttons {
    margin-left: auto;
    .btn {
      height: 36px;
      padding: 0 10px;
      background: rgba(208,208,208,0.1);
      border-radius: 8px;
      font-size: 12px;
      font-family: Inter;
      font-weight: bold;
      color: ~'var(--white-text)';
      line-height: 15px;
      border: 0;
      &:hover {
        background: rgba(208,208,208,0.3);
      }
    }
  }
  .btn-tag {
    float: right;
    width: 28px;
    height: 28px;
    background-color: ~"var(--card-bottom-bg)";
    border-radius: 8px;
    padding: 0;
    text-align: center;
    border: 0;
    font-size: 28px;
    color: ~'var(--card-button-text)';
    font-family: Inter;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 0;

    &:last-child {
      margin: 0;
    }

    img {
      width: 100%;
    }
  }
  .label-tag {
    height: 25px;
    padding: 0 15px;
    border-radius: 13px;
    opacity: 0.8;
    border: 1px solid ~'var(--list-item-text)';
    font-size: 12px;
    font-family: Inter;
    color: ~'var(--list-item-text)';
    line-height: 23px;
    margin-top: 5px;
  }
}
.label-tag {
  height: 25px;
  padding: 0 15px;
  border-radius: 13px;
  opacity: 0.8;
  border: 1px solid #fff;
  font-size: 12px;
  font-family: Inter;
  color: #FFFFFF;
  line-height: 23px;
  margin-top: 5px;
}
.card-label-tag {
  height: 24.1px;
  padding: 0 10px;
  background: rgba(208,208,208,0.3);
  border-radius: 13px;
  opacity: 0.8;
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  border: 0;
  color: #FFFFFF;
  line-height: 24px;
  transform: scale(0.83);
  margin: 0;
  margin-top: 2px;
}
@media (max-width: 800px) {
  .chat-btn {
    display: none;
  }
}

@primary-color: #1890ff;