.home-container {
	max-width: 1440px;
	min-height: 100vh;
	margin: 0 auto;
	padding: 24px 40px;
	background-color: ~"var(--main-bg)";
	// overflow: hidden;
	color: #fff;

	.ant-back-top:hover {
		opacity: 0.75;
	}

	.ant-back-top {
		right: 45px;
		bottom: 85px;
	}

	.add-container {
		.add-input {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 20px;
			position: relative;
			flex-direction: column;
			
			.input {
				width: 600px;
				height: 40px;
				background: ~"var(--card-bg)";
				border-radius: 8px;
				border: 1px solid ~"var(--right-text-background)";
			}
			.ant-input, .anticon {
				color: ~"var(--list-item-text)";
			}
			.ant-input::placeholder {
				color: ~"var(--select-arrow)";
			}
	
			.add-button {
				width: 112px;
				height: 40px;
				margin-top: 20px;
				background: ~"var(--list-item-border)";
				border-radius: 8px;
				border: 1px solid ~"var(--label-border)";
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--list-item-text)";
				line-height: 18px;
			}
		}
	}
}
@media (max-width: 700px) {
	.home-container {
		width: 100vw;
		padding: 20px !important;

		.home-header {
			.desc {
				width: 100%;
				margin: 20px 0;
				font-size: 30px;
			}
		}

		.home-content, .hosts-content {
			.filter {
				.filter-top {
					flex-wrap: wrap;
					justify-content: center;

					.state {
						flex-wrap: wrap;

						.state-tag {
							margin-bottom: 10px;
						}
					}

					.lang-layout {
						display: none;
					}
				}
			}

			.search-input {
				width: 100%;
				.input {
					width: 100%;
				}
			}
		}

		.add-container .add-input .input {
			width: 100%;
		}

		.ant-back-top {
			width: 58px;
			height: 58px;
			right: 5vw;
			bottom: 70px;
		}
	}
}

@primary-color: #1890ff;