.host-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  background-color: ~"var(--card-bg)";
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;

  .card-top {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: ~"var(--right-text-background)";
    cursor: pointer;

    .info {
      width: calc(100% - 76px);
      margin-left: 16px;

      .name,
      .account {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-family: Inter;
        color: ~"var(--list-item-text)";
      }
      .account {
        font-size: 14px;
        font-family: Inter;
        opacity: 0.5;
      }
      .host-name {
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .count {
        margin-left: 10px;
      }
    }
  }

  .host-avatar {
    position: relative;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;

    &.live {
      border: 2px solid rgb(55, 59, 255);
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .follow-state {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      left: 41px;
      top: 41px;
      border: 1px solid #1e262c;
      transform: none;
      border-radius: 50%;
      background-color: #7269ef;
      font-size: 11px;
      transition: all 0.5s;
      color: #fff;

      &.followed {
        background-color: #fff;
        color: #000;
      }
    }
  }
  .latest-space {
    height: 107px;
    margin: 16px 16px 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ~"var(--divider)";
    color: ~"var(--list-item-text)";
    cursor: pointer;

    &:hover {

      .space-name {
        text-decoration: underline;
      }
    }

    .space-name {
      width: 100%;
      height: 44px;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .space-labels {
      margin-top: 4px;
      height: 26px;
    }
  }
  .sub-title {
    font-size: 12px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 15px;
    opacity: 0.5;
  }
  .card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 12px;
    cursor: pointer;

    &:hover {

      .sub-title {
        text-decoration: underline;
      }
    }

    .count,
    .time {
      font-size: 12px;
      font-family: Inter;
      font-weight: bold;
      color: ~"var(--list-item-text)";
      line-height: 15px;
      margin-left: 20px;
      opacity: 0.6;
    }
    .time {
      display: flex;
      align-items: center;
      margin-left: 0;

      img {
        margin-right: 2px;
      }
    }
  }

  .label-tag {
    height: 25px;
    padding: 0 15px;
    border-radius: 13px;
    opacity: 0.8;
    border: 1px solid #fff;
    font-size: 12px;
    font-family: Inter;
    color: #ffffff;
    line-height: 23px;
    margin-top: 5px;
  }
  .card-label-tag {
    height: 24.1px;
    padding: 0 10px;
    background: rgba(208, 208, 208, 0.3);
    border-radius: 13px;
    opacity: 0.8;
    text-align: center;
    font-size: 12px;
    font-family: Inter;
    border: 0;
    color: ~"var(--right-text)";
    line-height: 24px;
    transform: scale(0.83);
    margin: 0;
    margin-top: 2px;
  }

  .order-info {
    display: none;
  }

  &.list {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 16px;
    height: 92px;
    background: ~'var(--list-item-bg)';
    border: 1px solid ~'var(--list-item-border)';
    margin-bottom: 2px !important;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background: rgba(114,105,239,0.1);
    }

    .card-top {
      background-color: transparent;

      .info {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .latest-space {
      height: auto;
      border: none;

      .space-name {
        height: auto;
        -webkit-line-clamp: 1;
      }

      .space-labels {
        display: none;
      }
    }
    .card-bottom {
      width: 150px;
      flex-direction: column;
      align-items: flex-start;
      margin-left: auto;
      padding-bottom: 0;

      .time {
        margin-top: 8px;
      }
    }
    .order-info {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 100px;

      .name,
      .account {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-family: Inter;
        color: ~"var(--list-item-text)";
      }
      .account {
        font-size: 14px;
        font-family: Inter;
        opacity: 0.5;
      }
      .host-name {
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .count {
        margin-left: 10px;
      }
    }
  }
}

.ant-tooltip-inner {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  color: ~'var(--black-text)';
}

@media (max-width: 800px) {
  .chat-btn {
    display: none;
  }
}

@primary-color: #1890ff;