.landing-container {
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 24px 40px;
  background-color: ~"var(--main-bg)";
  overflow: hidden;
  color: #fff;

  .landing-content {
    font-family: Inter;

    .welcome {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
    }

    .slogan {
      margin-bottom: 48px;

      .text {
        margin-top: 80px;
        margin-bottom: 60px;
        text-align: left;
        font-size: 60px;
        font-weight: bold;
        color: #ffffff;
        line-height: 72px;
        background: ~"var(--gradient-bg)";
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .buttons {
        text-align: left;
        .ant-btn {
          width: 160px;
          height: 40px;
          background: transparent;
          border-radius: 8px;
          border: 1px solid ~"var(--white-text)";
          margin-left: 20px;
          color: ~"var(--white-text)";

          &:first-child {
            margin: 0;
          }

          &:hover {
            color: ~"var(--list-item-text)";
          }

          &.explore {
            background-color: #7269ef;
            border-color: #7269ef;
            color: #fff;
          }
        }
      }
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .label {
        font-size: 18px;
        font-weight: bold;
        color: ~"var(--list-item-text)";
      }
    }

    .more-btn {
      margin-bottom: 60px;
    }

    .explore-more {
      width: 129px;
      height: 40px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid ~"var(--label-border)";
      color: ~"var(--white-text)";

      &:hover {
        color: ~"var(--list-item-text)";
      }
    }

    .carousel {
      width: 768px;
      margin-bottom: 36px;

      .carousel-item {
        display: inline-flex !important;
        flex-direction: column;
        width: 100%;
        height: 457px;
        padding: 16px 16px 20px;
        background: ~"var(--list-item-bg)";
        border-radius: 16px;

        .info {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: flex-start;
          height: 100%;
          padding: 20px 20px 10px;
          text-align: left;
          border-radius: 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .date {
            display: flex;
            align-items: center;
            height: 30px;
            font-size: 20px;
            font-weight: bold;

            .icon {
              padding-right: 5px;
              padding-top: 2px;
              transform: scale(1.1);
            }            
          }

          .title {
            margin: auto 0 20px;
            font-size: 32px;
            font-weight: bold;
            color: #fff;
            line-height: 39px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .labels {
            .label {
              padding: 4px 10px;
              margin: 0 4px 4px 0;
              border-radius: 16px;
              background-color: rgba(208,208,208,0.1);
              font-size: 12px;
              color: #FFF;
            }
          }

          .hosts {
            display: flex;
            margin-top: 20px;

            .user-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100px;
              cursor: pointer;

              .avatar {
                width: 53px;
                height: 53px;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                margin-bottom: 5px;
              }

              .name, .role {
                width: 90%;
                text-align: center;
                font-size: 12px;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .role {
                opacity: 0.6;
              }
            }
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 16px;

          .state {
            padding: 3px 10px;
            background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
            border-radius: 14px;
            font-size: 14px;
            color: #fff;

            &.scheduled {
              background: linear-gradient(225deg, #9881A3 0%, #4689A2 100%);
            }
            &.ended {
              background: linear-gradient(225deg, #3C2765 0%, #191C66 100%);
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;

            .button {
              width: 90px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 6px;
              font-size: 12px;
              font-weight: bold;
              color: ~"var(--label-active-bg)";
              background-color: ~"var(--card-bottom-bg)";
              border-radius: 8px;
              cursor: pointer;

              .icon {
                display: inline-block;
                width: 28px;
                padding-top: 5px;
                text-align: center;

                &.collect {
                  transform: scale(0.9);
                }
              }
            }
          }
        }
      }

      .ant-carousel .slick-dots-bottom {
        z-index: 9;
        bottom: -18px;
      }

      .ant-carousel .slick-dots {
        li {
          width: 40px;
          height: 8px;
          background: ~"var(--main-bg)";
          border-radius: 4px;
          border: 1px solid ~"var(--label-active-bg)";
          box-sizing: border-box;

          &.slick-active {
            background: ~"var(--label-active-bg)";
          }

          button {
            height: 0;
          }
        }
      }
    }

    .space-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 20px 0 0;

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 32.6%;
        margin-bottom: 20px;
        margin-left: 1.1%;
        padding: 16px;
        border-radius: 16px;
        background-color: ~"var(--list-item-bg)";

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        .date {

          .icon {
            position: relative;
            top: 3px;
            padding-right: 5px;
          }            
        }

        .info-card {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          height: 206px;
          margin-bottom: 16px;
          padding: 16px;
          border-radius: 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          .hosts {
            .user-info {
              display: flex;

              .avatar {
                margin-right: 10px;
                border: 2px solid #fff;
                border-radius: 50%;
              }
              .name-box {
                text-align: left;

                .name {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .role {
                  opacity: 0.6;
                }
              }
            }
          }
        }

        .state {
          padding: 3px 10px;
          background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
          border-radius: 14px;
          font-size: 14px;
          color: #fff;

          &.scheduled {
            background: linear-gradient(225deg, #9881A3 0%, #4689A2 100%);
          }
          &.ended {
            background: linear-gradient(225deg, #3C2765 0%, #191C66 100%);
          }
        }

        .title {
          margin: auto 0 20px;
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          line-height: 29px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .labels {
          max-width: calc(100% - 80px);

          .label {
            padding: 4px 10px;
            margin: 0 4px 4px 0;
            border-radius: 16px;
            background-color: rgba(208,208,208,0.1);
            font-size: 12px;
            color: #fff;
          }
        }

        .cover {
          width: 100%;
          height: 210px;
          border-radius: 9px;
          align-self: center;
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: auto;

          .btn-tag {
            width: 36px;
            height: 36px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: ~"var(--card-bottom-bg)";
            border-radius: 8px;
            padding: 0;
            text-align: center;
            border: 0;
            font-size: 28px;
            color: ~"var(--card-button-text)";
            font-family: Inter;
            cursor: pointer;
            margin-left: 8px;
            margin-right: 0;

            &:first-child {
              margin: 0;
            }
          }
        }
      }
    }

    .host-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .list-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 33%;
        margin-left: 12px;
        padding: 16px;
        border-radius: 16px;
        background-color: ~"var(--list-item-bg)";

        &:first-child {
          margin: 0;
        }

        .card-title {
          font-size: 16px;
          font-weight: bold;
          color: ~"var(--white-text)";
        }

        .host-item {
          display: flex;
          width: 100%;
          margin-top: 20px;
          cursor: pointer;

          .avatar {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            border: 2px solid #fff;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            flex: 1;

            .label,
            .value {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              color: ~"var(--white-text)";

              .host-name {
                display: inline-block;
                max-width: 280px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .value {
              font-size: 18px;
              font-weight: bold;
              color: ~"var(--list-item-text)";
            }
          }
        }
      }
    }

    .footer {
      position: relative;
      color: ~"var(--white-text)";
      font-size: 14px;

      .line {
        position: absolute;
        left: -50%;
        top: 0;
        width: 200vw;
        height: 1px;
        background-color: ~"var(--right-text-background)";
      }

      .media {
        padding: 20px 0 16px;
        height: 66px;

        .icon {
          display: inline-block;
          margin-left: 40px;
          color: ~"var(--label-text)";
          cursor: pointer;

          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
	.landing-container {
		width: 100vw;
		padding: 20px !important;

    .welcome {
      flex-direction: column;

      .slogan {
        margin-bottom: 20px;
        .text {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }
    .landing-content {
      .carousel {
        width: calc(100vw - 40px);

        .carousel-item {
          height: 300px;

          .info {
            .title {
              margin-bottom: 0;
              font-size: 20px;
              -webkit-line-clamp: 1;
            }
            .labels {
              display: none;
            }
            .hosts {
              margin-top: 10px;
              
              .user-info:nth-child(4n) {
                display: none;
              }
            }
          }
          .bottom {
            .buttons .button {
              width: 36px;
              .label {
                display: none;
              }
            }
          }
        }
      }
      .space-list {

        .info {
          width: 100%;
        }
      }
      .host-list {
        flex-direction: column;

        .list-card {
          width: 100%;
          margin: 15px 0 0;
        }
      }
    }
	}
}

@primary-color: #1890ff;