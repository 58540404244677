#terms {
  text-align: left;
  color: ~"var(--popover-text)";

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ~"var(--list-item-text)";
  }

  .subtitle {
    font-size: 16px;
    font-weight: bold;
    color: ~"var(--list-item-text)";
  }

  .info {
    margin-bottom: 20px;
  }

  .desc {
    margin-left: 20px;
    margin-bottom: 20px;

  }
}
@primary-color: #1890ff;