.AI-summary {
  font-family: Inter;
  text-align: left;

  .slogan {
    max-width: 750px;
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 60px;
    font-family: Inter;
    font-weight: bold;
    color: ~"var(--card-button-text)";
    line-height: 72px;

    .type1 {
      background: ~"var(--gradient-text-green)";
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .type2 {
      background: ~"var(--gradient-text-purple)";
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .gpt {
    margin-bottom: 80px;
    color: ~"var(--list-item-text)";

    .powered {
      opacity: 0.5;
    }
  }
  
  .summary-card {
    display: inline-block;
    width: 100%;
    padding: 16px;
    margin-right: 12px;
    margin-bottom: 20px;
    background: ~"var(--list-item-bg)";
    border-radius: 16px;
    cursor: pointer;

    .card-top {
      display: flex;
      flex-direction: column;
      height: 206px;
      padding: 16px;
      border-radius: 9px;
      border: 1px solid ~"var(--right-text-background)";
      background-size: cover;

      .gpt-icon {
        text-align: right;
      }
      .title {
        font-size: 20px;
        font-family: Inter;
        font-weight: bold;
        color: #fff;
        line-height: 24px;
        margin-top: auto;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .speakers {
        display: flex;
        align-items: flex-end;

        .date {
          display: flex;
          align-items: center;
          margin-left: auto;
        }

        .host {
          display: flex;
          align-items: center;

          .host-avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 1px solid #FFFFFF;
            overflow: hidden;
          }

          .host-name {
            max-width: 110px;
            margin-left: 5px;
            font-size: 12px;
            color: #fff;
            line-height: 15px;

            .twitter-id, .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .twitter-id {
              opacity: 0.6;
            }
          }
        }
        .users {
          width: 75px;
          margin-left: 16px;
          flex-shrink: 0;
          .user {
            display: inline-block;
            width: 20px;
          }
          .user-avatar {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid #fff;
      
            &:nth-child(1) {
              z-index: 9;
            }
            &:nth-child(2) {
              z-index: 8;
            }
            &:nth-child(3) {
              z-index: 7;
            }
            &:nth-child(4) {
              z-index: 6;
            }
          }
        }
      }
    }
    .summary-text {
      height: 120px;
      margin: 16px;
      font-size: 16px;
      color: ~"var(--list-item-text)";
      line-height: 20px;
      opacity: 0.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
  }
}
.summary-modal {

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ~"var(--list-item-text)";

    .title {
      display: flex;
      align-items: center;

      span {
        padding-left: 6px;
        height: 20px;
        font-size: 14px;
        font-family: Inter;
        font-weight: bold;
        line-height: 20px;
        opacity: 0.6;
      }
    }
    .close {
      cursor: pointer;
    }
  }

  &-content {
    max-height: 505px;

    .space {
      display: flex;
      align-items: center;
      margin: 20px 0;

      .space-title {
        flex: 1;
        font-size: 18px;
        font-family: Inter;
        font-weight: bold;
        color: ~"var(--list-item-text)";
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .gpt {
        color: ~"var(--list-item-text)";
      }
    }
    .summary-text {
      max-height: 385px;
      font-size: 16px;
      font-family: Inter;
      color: ~"var(--card-button-text)";
      line-height: 22px;
      overflow: auto;

      .sentence {
        text-indent: 30px;
      }
    }
    .button {
      width: 200px;
      height: 36px;
      margin: 20px auto 0;
      color: ~'var(--popover-text)';
      background: ~"var(--card-bottom-bg)";
      border-radius: 8px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {
  .AI-summary {
    .slogan {
      margin-top: 48px;
      font-size: 30px;
      line-height: 36px;
    }
    .gpt {
      margin-bottom: 30px;
    }
    .summary-list .summary-card {
      width: calc(100vw - 40px);
    }
  }
}
@primary-color: #1890ff;