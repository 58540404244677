.login-modal {

  .anticon-close {
    color: ~'var(--list-item-text)';
  }

  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-content {
    width: 440px;
    height: 340px;
    background: ~'var(--login-bg)';
    border-radius: 16px;

    .login-title {
      height: 21px;
      margin: 0 0 36px;
      font-size: 18px;
      text-align: center;
      font-family: Inter;
      font-weight: bold;
      color: ~'var(--list-item-text)';
      line-height: 21px;
    }

    .login-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .login-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 12px;
      padding: 1px;

      &-icon {
        width: 139px;
        margin-bottom: 25px;
      }
      &-title {
        height: 17px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: Inter;
        color: ~'var(--list-item-text)';
        line-height: 17px;
        opacity: 0.6;
      }
      .login-button {
        width: 201px;
        height: 36px;
        margin-top: 30px;
        border-radius: 8px;
        background: #7269EF;
        border-color: #7269EF;
      }
    }
  }
}
@media (max-width: 800px) {
  .login-modal .ant-modal-content {
    width: 100%;
  }
}
@primary-color: #1890ff;