.home-header {
  margin-bottom: 25px;

  .header-search {
    display: flex;
    align-items: center;

    .search-input {
      display: flex;
      justify-content: center;
      position: relative;

      .input {
        width: 230px;
        height: 40px;
        background: ~"var(--card-bg)";
        border-radius: 8px;
        border: 1px solid ~"var(--right-text-background)";
      }
      .ant-input,
      .anticon {
        color: ~"var(--list-item-text)";
      }
      .ant-input::placeholder {
        color: ~"var(--select-arrow)";
      }

      .add-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 112px;
        height: 40px;
        background: ~"var(--list-item-border)";
        border-radius: 8px;
        border: 1px solid ~"var(--label-border)";
        font-size: 14px;
        font-family: Inter;
        color: ~"var(--list-item-text)";
        line-height: 18px;
      }
    }

    .search-sub-title {
      margin: 20px 0;
      text-align: left;
      font-size: 18px;
      font-family: Inter;
      font-weight: bold;
      color: ~"var(--list-item-text)";
      line-height: 24px;
    }

    .load-more-btn {
      width: 112px;
      height: 40px;
      margin-top: 10px;
      background: ~"var(--list-item-border)";
      border-radius: 8px;
      border: 1px solid ~"var(--label-border)";
      font-size: 14px;
      font-family: Inter;
      color: ~"var(--list-item-text)";
      line-height: 18px;
    }
  }
  .top-bar {
    display: grid;
    grid-template-columns: 1fr 7fr 2fr;
    align-content: center;

    .logo {
      display: flex;
      align-items: center;
      width: 120px;
      margin-right: 30px;
      margin-bottom: 8px;
      cursor: pointer;
    }

    .line {
      position: absolute;
      left: 0;
      top: 91px;
      width: 100vw;
      height: 1px;
      background-color: ~"var(--divider)";
    }
    .header-tabs {
      display: flex;
    }
    .mobile-tabs {
      display: none;
    }

    .tab {
      display: flex;
      align-items: center;
      height: 67px;
      justify-self: self-start;
      margin: 0 15px;
      padding: 0 20px;
      border-bottom: 3px solid transparent;

      cursor: pointer;
      text-decoration: none;

      .tab-text {
        font-size: 16px;
        font-family: Inter;
        color: ~"var(--list-item-text)";
        line-height: 24px;
        opacity: 0.5;
      }

      &.active {
        border-bottom: 3px solid #7269ef;

        .tab-text {
          opacity: 1;
        }
      }

      .ant-badge {
        color: ~"var(--list-item-text)";
        .ant-badge-dot {
          top: -2px;
          right: -3px;
          background: #7269ef;
          box-shadow: none;
        }
      }
    }
  }
  .header-btn {
    grid-area: 1/3/2/4;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .balance {
      height: 40px;
      margin-right: 10px;
      padding: 1px;
      border-radius: 8px;
      background-image: ~"var(--gradient-bg)";

      .balance-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: ~"var(--label-bg)";
        font-size: 16px;
        font-family: Inter;
        color: ~"var(--label-text)";
        line-height: 38px;
        cursor: pointer;
        transition: all 0.5s;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;

        &:hover {
          background: transparent;
          color: ~"var(--label-bg)";
        }

        .label {
          padding-left: 5px;
        }
      }
    }
  }
  .login {
    min-width: 120px;
    height: 40px;
    // margin-left: auto;
    // margin-top: 13px;
    padding: 1px;
    border-radius: 8px;
    background-image: ~"var(--gradient-bg)";

    .login-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: ~"var(--label-bg)";
      font-size: 16px;
      font-family: Inter;
      color: ~"var(--label-text)";
      line-height: 38px;
      cursor: pointer;
      transition: all 0.5s;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;

      &:hover {
        background: transparent;
        color: ~"var(--label-bg)";
      }
    }
    .addr {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

      > span {
        padding-right: 5px;
      }
    }
  }
  .desc {
    max-width: 900px;
    margin: 40px auto;
    font-size: 60px;
    font-family: Inter;
    color: #ffffff;
    line-height: 72px;
    background: ~"var(--gradient-bg)";
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.header-search-result {
  // width: 100vw;
  // padding: 16px 10vw;

  .ant-popover-content {
    // max-width: 1280px;
    // margin: 0 auto;
    border-radius: 8px;

    .ant-popover-inner-content {
      max-height: 50vh;
      width: 400px;
      overflow: auto;
      text-align: center;
    }
  }

  .search-sub-title {
    margin: 20px 0;
    text-align: left;
    font-size: 18px;
    font-family: Inter;
    font-weight: bold;
    color: ~"var(--list-item-text)";
    line-height: 24px;
  }

  .load-more-btn {
    width: 112px;
    height: 40px;
    margin-top: 10px;
    background: ~"var(--list-item-border)";
    border-radius: 8px;
    border: 1px solid ~"var(--label-border)";
    font-size: 14px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 18px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    color: ~"var(--list-item-text)";
    opacity: 0.6;

    img {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
.ant-select-dropdown {
  background-color: ~"var(--popover-bg)";

  .ant-select-item {
    background-color: ~"var(--popover-bg)";
    color: ~"var(--popover-text)";
  }
}

.ant-drawer-body {
  background-color: ~"var(--card-bg)";
}

.ant-divider {
  border-color: ~"var(--divider)";
}

.user-login-popover {
  // color:rgba(208, 208, 208, 1);
  display: flex;
  justify-content: center;
  width: 330px;

  .user-connect {
    position: relative;
    height: 136px;
    width: 140px;
    padding-top: 20px;
    .button-container {
      display: flex;
      justify-content: center;

      .connect-button {
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }

    .login-tip {
      text-align: center;
      margin-top: 12px;
      width: 140px;
      height: 24px;
      font-size: 14px;
      font-family: Inter;
      line-height: 24px;
    }
    .disconnect {
      cursor: pointer;
      padding: 4px 10px;
      margin-top: 4px;
      height: 20px;
      background: rgba(208, 208, 208, 0.3);
      border-radius: 13px;
      font-size: 10px;
      font-family: Inter;
      line-height: 12px;
    }
    .user-info {
      margin-top: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .open-icon {
        padding-left: 3px;
      }
    }
  }
}
.mode-switch {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;

  .switch-icon {
    height: 24px;
    width: 25px;
  }

  .anticon {
    font-size: 18px;
    margin-right: 3px;
  }
}

.ant-popover-inner {
  border-radius: 8px;
  background-color: ~"var(--popover-bg)";

  .ant-popover-inner-content {
    color: ~"var(--popover-text)";
  }

  .user-content-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-radius: 4px;
    cursor: pointer;

    > span {
      padding-right: 5px;
    }

    &.logout {
      color: #ff4d4f;
    }

    &:first-child {
      margin-bottom: 8px;
    }
  }
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: ~"var(--popover-bg)";
}
.modal-title {
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  color: ~"var(--list-item-text)";
  line-height: 25px;
}
.balance-modal {
  .contact {
    margin-top: 20px;
    color: ~"var(--list-item-text)";
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  .total {
    margin: 40px 0;
    font-size: 36px;
    font-family: Inter;
    font-weight: bold;
    color: ~"var(--list-item-text)";
    line-height: 44px;

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      font-size: 12px;
      line-height: 20px;
      opacity: 0.6;

      .label {
        margin: 0;
        padding-left: 5px;
        font-size: 14px;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      height: 89px;
      margin: 0 10px;
      padding: 8px;
      background: ~"var(--card-bottom-bg)";
      border-radius: 12px;
      color: ~"var(--list-item-text)";
      cursor: pointer;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        color: #fff;
        background-color: #7269ef;
        border-radius: 12px;
      }

      .name {
        font-size: 14px;
        font-family: Inter;
        font-weight: bold;
        line-height: 17px;
        opacity: 0.6;
      }
    }
  }

  .assets {
    max-height: 206px;
    overflow: auto;
    .no-asset {
      height: 50px;
      line-height: 50px;
      color: ~"var(--list-item-text)";
      opacity: 0.6;
    }
    .coin {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0;
      font-size: 14px;
      font-family: Inter;
      color: ~"var(--list-item-text)";
      font-weight: bold;

      .coin-name {
        display: flex;
        align-items: center;
      }

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }

  .history-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px 12px 0;
    border-top: 1px solid ~"var(--divider)";
    text-align: left;
    color: ~"var(--list-item-text)";

    &:first-child {
      border: 0;
    }

    .date {
      width: 80px;
      margin-right: 10px;
      opacity: 0.6;
    }
    .type {
      opacity: 0.6;

      .type-label,
      .hash {
        display: flex;
        align-items: center;

        .send-list {
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .open-tx {
        transform: scale(0.83);
        margin-left: 2px;
        cursor: pointer;
      }

      .state {
        width: 72px;
        height: 19px;
        margin-left: 10px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        &.Success {
          background: rgba(85, 181, 149, 0.1);
          color: #55b595;
        }
        &.Failed {
          color: #ff6187;
          background: rgba(255, 97, 135, 0.1);
        }
        &.Pending {
          color: ~"var(--select-arrow)";
          background: ~"var(--card-bottom-bg)";
        }
      }
    }
    .record {
      margin-left: auto;

      .record-coin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: Inter;
        // font-weight: bold;
        color: ~"var(--list-item-text)";
        line-height: 17px;

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }

        .value {
          // color: #55b582;
          margin-left: 8px;
        }
      }
      .dollar {
        text-align: right;
        // color: #55b582;
      }
    }

    // &.Claim, &.Send {
    //   .record .dollar, .record .record-coin .value {
    //     color: #ff4d4f;
    //   }
    // }
    // &.Return {
    //   .record .dollar, .record .record-coin .value {
    //     color: #55b582;
    //   }
    // }
  }
}
.ant-tabs-tab {
  padding: 6px 10px;
}
.ant-tabs-tab-btn {
  font-family: Inter;
  color: ~"var(--list-item-text)";
  font-size: 16px;
  opacity: 0.6;
}
.ant-tabs-ink-bar {
  background: #7269ef;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: ~"var(--list-item-text)";
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid ~"var(--divider)";
}
.deposit-modal,
.claim-modal {
  padding-top: 24px;

  .select {
    width: 100%;
    margin: 20px 0;
    text-align: left;

    &.contract {
      margin-bottom: 120px;
    }

    .select-label {
      margin-bottom: 12px;
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
      color: ~"var(--list-item-text)";
      line-height: 17px;
    }

    .value {
      .ant-select,
      .ant-input {
        width: 100%;
        height: 53px;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .contract-input {
        height: 53px;
        border-radius: 12px;
        background-color: ~"var(--card-bottom-bg)";
        color: ~"var(--list-item-text)";
        border: none;
      }
      .ant-select-arrow {
        color: ~"var(--list-item-text)";
      }

      .ant-select-selection-placeholder {
        line-height: 53px;
        color: ~"var(--list-item-text)";
        opacity: 0.6;
      }

      .add-send-target {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 309px;
        margin-top: 12px;
        font-family: Inter;
        font-size: 14px;
        color: ~"var(--list-item-text)";
      }
    }
  }

  .add-token {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    font-size: 14px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ~"var(--list-item-text)";
    opacity: 0.6;
    cursor: pointer;

    &-icon {
      margin-right: 5px;
    }
  }
  .qrCode {
    margin-top: 20px;
    margin-bottom: 20px;

    canvas {
      border: 4px solid #fff;
    }
  }
  .tips,
  .label {
    width: 300px;
    height: 34px;
    margin: 0 auto;
    font-size: 14px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 17px;
    opacity: 0.5;
  }
  .label {
    height: auto;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .address {
    height: 17px;
    margin: 0 auto 76px;
    font-size: 14px;
    font-weight: bold;
    color: ~"var(--list-item-text)";
    line-height: 17px;
  }
}
.ant-modal-close,
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: ~"var(--list-item-text)";
}
.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .deposit-btn {
    width: 201px;
    height: 36px;
    border-radius: 8px;
    font-size: 12px;
    font-family: Inter;
    font-weight: bold;
    line-height: 15px;
    background: ~"var(--card-bottom-bg)";
    color: ~"var(--card-button-text)";
    border: none;

    &.copy {
      background: #7269ef;
      color: #fff;
    }
  }
}
.claim-modal {
  padding-top: 0;
  text-align: center;

  .tips-title {
    margin: 24px 0 8px;
    font-size: 18px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 21px;
  }
  .tips {
    font-size: 12px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 15px;
    opacity: 0.6;
  }
  .select.network {
    margin-bottom: 120px;
  }
}
.ant-select-dropdown {
  z-index: 99999;
  border-radius: 12px;
}
.send-modal {
  padding-top: 20px;

  .item {
    display: flex;
    align-items: flex-start;
    margin: 25px 0;

    .label {
      width: 62px;
      height: 53px;
      font-size: 16px;
      font-family: Inter;
      color: ~"var(--list-item-text)";
      line-height: 53px;
      text-align: left;
    }

    .value {
      width: 346px;
      min-height: 53px;

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 100%;
        border-radius: 12px;
        background-color: ~"var(--card-bottom-bg)";
        border: none;
      }
      .ant-select-arrow {
        color: ~"var(--list-item-text)";
      }

      .add-send-target {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 309px;
        margin-top: 12px;
        font-family: Inter;
        font-size: 14px;
        color: ~"var(--list-item-text)";

        .add-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 144px;
          height: 53px;
          background: ~"var(--card-bottom-bg)";
          border-radius: 12px;
          color: ~"var(--select-arrow)";
          cursor: pointer;
        }
      }

      .send-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .ant-input {
          color: ~"var(--list-item-text)";
        }

        .address,
        .count,
        .ant-input-number-input {
          width: 218px;
          height: 53px;
          margin-right: 4px;
          background: ~"var(--card-bottom-bg)";
          border-radius: 12px;
          border: 1px solid transparent;
          color: ~"var(--list-item-text)";
          font-family: Inter;

          &.ant-input-number-status-error,
          &.ant-input-status-error {
            border: 1px solid rgba(242, 96, 117, 0.5);
            color: #f26075;

            input,
            .ant-input {
              color: #f26075;
            }
          }
        }
        .ant-input-affix-wrapper-status-error {
          border: 1px solid rgba(242, 96, 117, 0.5);
          color: #f26075;
        }
        .count {
          background: none;
        }
        .count,
        .ant-input-number-input {
          width: 87px;
        }
        .remove {
          color: ~"var(--list-item-text)";
          opacity: 0.2;
          cursor: pointer;
        }
      }
    }
  }
}

.search-token {
  width: 392px;
  height: 53px;
  margin: 8px 8px 4px;
  background: ~"var(--card-bg)";
  border-radius: 12px;
  border: 1px solid ~"var(--right-text-background)";

  .ant-input {
    color: ~"var(--list-item-text)";
  }
  .ant-input::placeholder,
  .anticon {
    color: ~"var(--select-arrow)";
  }
}

.coin-select-item {
  display: flex;
  align-items: center;
  height: 53px;

  .coin-icon {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
  .coin-name,
  .amount {
    margin-left: 6px;
    margin-right: 6px;
    font-size: 18px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 21px;
  }
  .amount {
    opacity: 0.5;
  }

  &.large {
    border-radius: 8px;

    &:hover {
      background-color: rgba(208, 208, 208, 0.05);
    }
    .coin-icon {
      width: 40px;
      height: 40px;
    }
    .coin-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 12px;
      font-size: 18px;
      font-family: Inter;
      color: ~"var(--list-item-text)";

      .name {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
      }
    }
    .amount {
      margin-left: auto;
      opacity: 1;
    }
  }
}
.ant-select-selector .ant-select-selection-item .coin-select-item.large:hover {
  background-color: transparent;
}
.tips-modal {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  color: ~"var(--list-item-text)";
  line-height: 17px;

  .img {
    margin-top: 35px;
  }

  .tips {
    width: 298px;
    margin: 17px auto 43px;
    opacity: 0.6;
  }

  .modal-buttons {
    justify-content: center;
  }
}
@media (max-width: 700px) {
  .home-header {
    .header-search {
      display: none;
    }
    .top-bar {
      .login {
        grid-area: 1/2/2/4;
        margin-top: 0;

        .login-inner .name {
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .header-tabs {
        display: none;
      }
      .mobile-tabs {
        display: flex;
        grid-area: 2/1/3/4;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        color: ~"var(--white-text)";

        .currentTab {
          padding-left: 10px;
        }
      }
      .line {
        display: none;
      }
      .balance {
        .balance-button .label {
          display: none;
        }
      }
    }
  }
  .ant-dropdown-menu {
    margin-top: 5px;
    background-color: ~"var(--list-item-bg)";
    border-radius: 8px;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: ~"var(--white-text)";
  }
  .modal-buttons {
    .deposit-btn {
      margin-left: 10px;

      &:first-child {
        margin: 0;
      }
    }
  }
  .send-modal {
    .item {
      flex-direction: column;
    }
  }
}
.connect-modal {
  width: 400px !important;
  .wallet-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Inter;
    font-weight: 600;
    color: var(--list-item-text);
    line-height: 25px;
    cursor: pointer;
    color: var(--list-item-text);
    img {
      width: 40px;
      margin: 0 8px;
    }
    &.argent {
      margin-left: 0;
    }
    margin: 16px;
  }
}

@primary-color: #1890ff;