.plyr__controls {
  border-radius: 12px !important;
}

.caption-list-container {
  overflow-y: auto;
  height:calc(100vh - 586px);
  .caption-container {
    display: flex;
    cursor: pointer;
    color: var(--proposal-title);
    opacity: 0.5;
    line-height: 20px;
    padding: 4px;
    &.active,&:hover {
      opacity: 1;
      background-color: var(--right-text-background);
    }

    .caption-time{
      margin-right: 12px;
    }
  }
}

@primary-color: #1890ff;