.send-red-packet,
.red-packet-detail {
  color: ~"var(--list-item-text)";
  .back-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    .back-button {
      background: var(--card-icon-btn) !important;
      width: 220px;
      border-radius: 8px;
      border: none;
    }
  }

  .title {
    height: 21px;
    margin: 0 0 36px;
    font-size: 18px;
    text-align: center;
    font-family: Inter;
    font-weight: bold;
    color: ~"var(--list-item-text)";
    line-height: 21px;
  }

  .ant-select {
    color: ~"var(--list-item-text)";
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
      border-radius: 12px;
      background-color: ~"var(--card-bottom-bg)";
      border: none;
    }
    .ant-select-arrow {
      color: ~"var(--list-item-text)";
    }
  }
  .ant-select,
  .ant-input {
    // width: 100%;
    height: 53px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number,
  .ant-input {
    height: 53px;
    border-radius: 12px;
    background-color: ~"var(--card-bottom-bg)";
    color: ~"var(--list-item-text)";
    border: none;
    line-height: 53px;

    .ant-select-selection-item {
      line-height: 53px;
    }
  }
  .ant-select-arrow,
  .ant-input-number-input,
  .ant-input {
    color: ~"var(--list-item-text)";
  }

  .ant-select-selection-placeholder {
    line-height: 53px;
    color: ~"var(--list-item-text)";
    opacity: 0.6;
  }

  .content {
    .item {
      margin: 10px 0;

      .label {
        display: inline-block;
        width: 120px;
      }
    }
  }
}
.red-packet-info {
  display: flex;
  .red-packet-text-container {
    margin: 8px;
  }
}
.red-packet-sender {
  display: flex;
  align-items: center;
  .red-packet-sender-name {
    .sender-icon {
      border-radius: 50%;
    }
  }
}
.receiver-list {
  max-height: 400px;
  overflow: auto;
  .red-packet-receiver {
    border-bottom: 1px solid #36404a;
    border-top: 1px solid #36404a;
    padding: 8px;
    display: flex;
    .receiver-icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: block;
      margin-right: 10px;
    }
    .user-and-time {
      flex: 1 0 auto;
      font-size: 14px;
      font-family: Inter-Regular, Inter;
      font-weight: 400;
      color: var(--white-text);
      line-height: 17px;
      .time {
        opacity: 0.6;
      }
    }
    .user-amount {
      img {
        width: 18px;
        height: 18px;
      }
      .asset-symbol {
        width: 40px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: var(--white-text);
        line-height: 20px;
      }
      .amount {
        width: 50px;
        height: 18px;
        font-size: 14px;
        font-family: Inter-SemiBold, Inter;
        font-weight: 600;
        color: var(--white-text);
        line-height: 17px;
        text-align: center;
      }
    }
  }
}
.red-packet-open-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .red-packet-open-content {
    height: 500px;
    position: relative;
    .center-img {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 260px;
    }
    .open-button {
      background-color: #7269ef !important;
      border-radius: 8px;
      width: 220px;
      border: none;
      margin-top: 130px;
    }
  }
}
.back-button {
  background: var(--card-icon-btn) !important;
  width: 220px;
  border-radius: 8px;
  border: none;
  color: var(--white-text);
}

.red-packet-receive-detail-modal {
  .ant-modal-body {
    padding: 0 !important;
    border-radius: 21px;
    position: relative;
    .red-packet-open-content {
      position: relative;

      .sender-info {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        .sender-icon {
          border-radius: 50%;
        }
        .sender-nick {
          width: 400px;
          margin-top: 20px;
          font-size: 24px;
          font-family: Inter-Medium, Inter;
          font-weight: 500;
          color: #ffffff;
          line-height: 29px;
        }
        .sender-text {
          width: 400px;
          margin-top: 20px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
        }
      }

      .receive-info {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 21px;
        }
        .asset-symbol {
          width: 50px;
          height: 21px;
          font-size: 18px;
          font-family: Inter-Medium, Inter;
          font-weight: 500;
          color: var(--white-text);
          line-height: 21px;
        }
      }
      .receive-amount {
        height: 49px;
        font-size: 40px;
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        color: var(--white-text);
        line-height: 49px;
        margin-bottom: 50px;
      }
      .footer {
        padding-bottom: 14px;
        .open-button {
          margin-left: 6px;
          background-color: #7269ef !important;
          border-radius: 8px;
          width: 220px;
          border: none;
        }
      }
    }
  }
}
.red-packet-over-modal {
  .ant-modal-body {
    border-radius: 21px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .red-packet-over-content {
    position: relative;
    height: 500px;
    .red-packet-over {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .tip {
      color: var(--label-text);
      font-size: 24px;
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      line-height: 29px;
      opacity: 0.6;
    }
    .footer {
      margin-top: 30px;
    }
  }
}

@primary-color: #1890ff;