.hosts-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.ant-spin-container,
	.ant-spin-nested-loading {
		width: 100%;
	}

	.no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: ~"var(--list-item-text)";
		opacity: 0.6;

		img {
			margin-top: 80px;
			margin-bottom: 30px;
		}
	}

	.search-input {
    display: flex;
		align-self: flex-start;
    margin-bottom: 20px;
    position: relative;
    
    .input {
      width: 500px;
      height: 40px;
      background: ~"var(--card-bg)";
      border-radius: 8px;
      border: 1px solid ~"var(--right-text-background)";
			transition: ease-in-out 0.5s;

			&.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
				width: 600px;
			}
    }
    .ant-input, .anticon {
      color: ~"var(--list-item-text)";
    }
    .ant-input::placeholder {
      color: ~"var(--select-arrow)";
    }
  }

	#scrollableDiv {
		.ant-spin-container,
		.ant-spin-nested-loading {
			border-radius: 16px 16px 0px 0px;
			overflow: hidden;
		}
	}

	.filter {
		width: 100%;
		.filter-top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;

			.state {
				display: flex;
				align-items: center;
			}

			.ant-badge {
				color: ~"var(--list-item-text)";
				.ant-badge-dot {
					top: 10px;
					right: 20px;
					background: #7269EF;
					box-shadow: none;
				}
			}

			.state-tag {
				display: flex;
				align-items: center;
				height: 40px;
				padding: 0 20px;
				background: ~"var(--label-bg)";
				border-radius: 8px;
				border: 1px solid ~"var(--label-border)";
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--label-text)";
				line-height: 38px;
				transition: all 0.5s;

				.anticon-fire {
					padding-right: 3px;
				}

				&:hover {
					background: ~"var(--label-hover-bg)";
					border: 1px solid #6e6e6e;
				}

				&.ant-tag-checkable-checked {
					background: ~"var(--label-active-bg)";
					border-color: #d0d0d0;
					color: ~"var(--black-text)";

					&.live {
						border: 0;
						color: #fff;
						background: linear-gradient(
							225deg,
							#995dfb 0%,
							#373bff 100%
						);
					}
				}
				img {
					margin-left: 3px;
				}
			}
			.filter-select {
				.ant-select-selector {
					width: 130px;
					height: 40px;
					background: ~"var(--label-bg)";
					border-radius: 0px 8px 8px 0px;
					color: ~"var(--label-text)";
					border: 1px solid ~"var(--label-border)";

					.ant-select-selection-item {
						line-height: 38px;
					}
					&:hover {
						background: ~"var(--label-hover-bg)";
						border: 1px solid #6e6e6e;
					}
				}
				.ant-select-arrow {
					color: ~"var(--select-arrow)";
				}

				&:first-child {
					.ant-select-selector {
						border-radius: 8px 8px 8px 8px;
					}
				}
			}
			.ant-select-single.ant-select-open .ant-select-selection-item {
				color: ~"var(--label-text)";
			}
			.lang-layout {
				display: flex;

				.toggle-live {
					display: flex;
					align-items: center;

					.label {
						padding: 0 4px;
						font-size: 14px;
						font-family: Inter;
						font-weight: 400;
						color: ~'var(--list-item-text)';
						line-height: 20px;
					}

					.ant-switch {
						background-color: ~"var(--select-arrow)";
					}

					.ant-switch-checked {
						background-color: #7269EF;
					}
				}

				.order-radio {
					height: 40px;
					font-size: 14px;
					font-family: Inter;
					color: ~"var(--label-text)";

					&:hover {
						background: ~"var(--label-hover-bg)";
						border: 1px solid #6e6e6e;
					}

					&.checked {
						background: ~"var(--label-active-bg)";
						color: ~"var(--black-text)";
					}

					&:first-child {
						border-radius: 8px 0 0 8px;
					}

					&:last-child {
						border-radius: 0 8px 8px 0;
					}
				}

				.layout {
					display: flex;
					margin-left: auto;
				}
				.layout-tag {
					padding: 0 8px;
					line-height: 35px;
					transition: all 0.5s;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
		.filter-bottom {
			.label-tag {
				position: relative;
				height: 25px;
				padding: 0 15px;
				margin-bottom: 10px;
				background: ~"var(--label-bg)";
				border-radius: 13px;
				border: 1px solid ~"var(--label-border)";
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--label-text)";
				line-height: 23px;
				transition: all 0.5s;

				&:hover {
					background: ~"var(--label-hover-bg)";
					border: 1px solid #6e6e6e;
				}

				&.ant-tag-checkable-checked {
					background: ~"var(--label-active-bg)";
					border-color: #d0d0d0;
					color: ~"var(--black-text)";
				}

				.cancel-label {
					position: absolute;
					top: -7px;
					right: -7px;
					width: 14px;
					height: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.ant-form-inline .ant-form-item {
		width: 200px;
	}

	.home-list {
		margin-top: 20px;
	}

	.login {
		width: 441px;
		height: 332px;
		background: ~'var(--login-bg)';
		border-radius: 16px;
		padding: 20px;

		.login-title {
			height: 21px;
			margin: 40px 0;
			font-size: 18px;
			text-align: center;
			font-family: Inter;
			font-weight: bold;
			color: ~'var(--list-item-text)';
			line-height: 21px;
		}

		.login-item {
			height: 83px;
			margin-bottom: 12px;
			border-radius: 12px;
			padding: 1px;
			background: ~'var(--gradient-bg)';
			
			&-inner {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 16px;
				border-radius: 12px;
				background: ~'var(--login-bg)';
				cursor: pointer;
			}
			&-icon {
				width: 36px;
				margin-right: 16px;
			}
			&-info {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
			&-title {
				height: 17px;
				margin-bottom: 5px;
				font-size: 14px;
				font-family: Inter;
				font-weight: bold;
				color: ~'var(--list-item-text)';
				line-height: 17px;
			}
			&-desc {
				font-size: 12px;
				font-family: Inter;
				color: ~'var(--list-item-text)';
				line-height: 15px;
				text-align: left;
				opacity: 0.6;
			}
		}
	}
	.list-container {
		width: 100%;

		.result {
			border-radius: 16px 16px 0 0;
			overflow: hidden;
		}
	}
	.hosts-sub-title {
    margin: 20px 0;
    text-align: left;
    font-size: 18px;
    font-family: Inter;
    font-weight: bold;
    color: ~"var(--list-item-text)";
    line-height: 24px;
  }

  .load-more-btn {
    width: 112px;
    height: 40px;
    margin-top: 10px;
    background: ~"var(--list-item-border)";
    border-radius: 8px;
    border: 1px solid ~"var(--label-border)";
    font-size: 14px;
    font-family: Inter;
    color: ~"var(--list-item-text)";
    line-height: 18px;
  }
}

.ant-select-dropdown {
	background-color: ~"var(--popover-bg)";

	.ant-select-item {
		background-color: ~"var(--popover-bg)";
		color: ~"var(--popover-text)";
	}
}

.ant-drawer-body {
	background-color: ~"var(--card-bg)";
}

.ant-divider {
	border-color: ~"var(--divider)";
}

.ant-popover-inner {
	border-radius: 8px;
	background-color: ~"var(--popover-bg)";

	.ant-popover-inner-content {
		color: ~"var(--popover-text)";
	}

	.user-content-item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 0;
		border-radius: 4px;
		cursor: pointer;

		> span {
			padding-right: 5px;
		}

		&.logout {
			color: #ff4d4f;
		}

		&:first-child {
			margin-bottom: 8px;
		}
	}
}
.ant-popover-arrow-content {
	--antd-arrow-background-color: ~"var(--popover-bg)";
}
.anticon-close {
	color: ~"var(--black-text)";
}
.ant-spin-container > .ant-row {
	margin: 0 !important;
}
.date-card {
	padding: 10px 8px 0;

	.date {
		height: 30px;
		text-align: left;

		span {
			display: block;
			width: 100px;
			height: 100%;
			text-align: center;
			background: ~"var(--gradient-bg)";
			font-size: 16px;
			font-family: Inter;
			color: ~"var(--black-text)";
			line-height: 32px;
			border-radius: 12px 12px 0px 0px;
		}
	}
	.line {
		height: 1px;
		margin-bottom: 20px;
		background: ~"var(--gradient-bg)";
	}
}

@primary-color: #1890ff;