.home-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.search-input {
    display: flex;
		align-self: flex-start;
    margin-bottom: 20px;
    position: relative;
    
    .input {
      width: 500px;
      height: 40px;
      background: ~"var(--card-bg)";
      border-radius: 8px;
      border: 1px solid ~"var(--right-text-background)";
			transition: ease-in-out 0.5s;

			&.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
				width: 600px;
			}
    }
    .ant-input, .anticon {
      color: ~"var(--list-item-text)";
    }
    .ant-input::placeholder {
      color: ~"var(--select-arrow)";
    }
  }

	.search-result {
		width: 100%;

		.search-sub-title {
			margin: 20px 0;
			text-align: left;
			font-size: 18px;
			font-family: Inter;
			font-weight: bold;
			color: ~"var(--list-item-text)";
			line-height: 24px;
		}
	
		.load-more-btn {
			width: 112px;
			height: 40px;
			margin-top: 10px;
			background: ~"var(--list-item-border)";
			border-radius: 8px;
			border: 1px solid ~"var(--label-border)";
			font-size: 14px;
			font-family: Inter;
			color: ~"var(--list-item-text)";
			line-height: 18px;
		}
	}

	.ant-spin-container,
	.ant-spin-nested-loading {
		width: 100%;
	}

	#scrollableDiv {
		.ant-spin-container,
		.ant-spin-nested-loading {
			border-radius: 16px 16px 0px 0px;
			overflow: hidden;
		}
	}

	.filter {
		width: 100%;
		.filter-top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;

			.state {
				display: flex;
				align-items: center;
			}

			.state-tag {
				display: flex;
				align-items: center;
				height: 40px;
				padding: 0 20px;
				background: ~"var(--label-bg)";
				border-radius: 8px;
				border: 1px solid ~"var(--label-border)";
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--label-text)";
				line-height: 38px;
				transition: all 0.5s;

				.anticon-fire {
					padding-right: 3px;
				}

				&:hover {
					background: ~"var(--label-hover-bg)";
					border: 1px solid #6e6e6e;
				}

				&.ant-tag-checkable-checked {
					background: ~"var(--label-active-bg)";
					border-color: #d0d0d0;
					color: ~"var(--black-text)";

					&.live {
						border: 0;
						color: #fff;
						background: linear-gradient(
							225deg,
							#995dfb 0%,
							#373bff 100%
						);
					}
				}
				img {
					margin-left: 3px;
				}
			}
			.filter-select {
				.ant-select-selector {
					width: 130px;
					height: 40px;
					background: ~"var(--label-bg)";
					border-radius: 0px 8px 8px 0px;
					color: ~"var(--label-text)";
					border: 1px solid ~"var(--label-border)";

					.ant-select-selection-item {
						line-height: 38px;
					}
					&:hover {
						background: ~"var(--label-hover-bg)";
						border: 1px solid #6e6e6e;
					}
				}
				.ant-select-arrow {
					color: ~"var(--select-arrow)";
				}

				&:first-child {
					.ant-select-selector {
						border-radius: 8px 8px 8px 8px;
					}
				}
			}
			.ant-select-single.ant-select-open .ant-select-selection-item {
				color: ~"var(--label-text)";
			}
			.lang-layout {
				display: flex;
				width: 250px;

				.layout {
					display: flex;
					margin-left: auto;
				}
				.layout-tag {
					padding: 0 8px;
					line-height: 35px;
					transition: all 0.5s;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
		.filter-bottom {
			.label-tag {
				position: relative;
				height: 25px;
				padding: 0 15px;
				margin-bottom: 10px;
				background: ~"var(--label-bg)";
				border-radius: 13px;
				border: 1px solid ~"var(--label-border)";
				font-size: 14px;
				font-family: Inter;
				color: ~"var(--label-text)";
				line-height: 23px;
				transition: all 0.5s;

				&:hover {
					background: ~"var(--label-hover-bg)";
					border: 1px solid #6e6e6e;
				}

				&.ant-tag-checkable-checked {
					background: ~"var(--label-active-bg)";
					border-color: #d0d0d0;
					color: ~"var(--black-text)";
				}

				.cancel-label {
					position: absolute;
					top: -7px;
					right: -7px;
					width: 14px;
					height: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.ant-form-inline .ant-form-item {
		width: 200px;
	}

	.home-list {
		margin-top: 20px;
	}
}
.chat-space {
	display: flex;
	flex-direction: column;
	height: 220px;
	padding: 16px 16px 8px;
	background: linear-gradient(225deg, #995dfb 0%, #373bff 100%);
	text-align: left;
	position: relative;
	&.scheduled {
		background: linear-gradient(225deg, #9881a3 0%, #4689a2 100%);
	}
	&.ended {
		background: linear-gradient(225deg, #3c2765 0%, #191c66 100%);
	}

	.close-btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.anticon {
			margin-left: 8px;
			color: #fff;
		}
		img {
			cursor: pointer;
		}
	}
	.title {
		margin-top: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 18px;
		font-family: Inter;
		font-weight: bold;
		color: #ffffff;
		line-height: 21px;
	}

	.state {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 14px;
		font-family: Inter;
		font-weight: bold;
		color: #ffffff;
		line-height: 17px;

		.hostname {
			font-size: 14px;
			font-family: Inter;
			color: #ffffff;
			line-height: 17px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.audio-control-btn-container{
		display: flex;
		
		.audio-control-btn{
			
			cursor: pointer;
			color:#fff;
			opacity: 0.8;
			display: flex;
			padding:0 4px;
			align-items: center;
			height: 32px;
			background: rgba(17,21,24,0.3);
			border-radius: 7px;
			margin-right: 5px;
		}
	}

	.labels {
		margin-top: auto;
		display: flex;
		justify-content: space-between;
	}
	.links{
		display: flex;
		.btn-tag {
		width: 28px;
		height: 28px;
		background-color: rgba(208, 208, 208, 0.1);
		border-radius: 8px;
		padding: 0;
		text-align: center;
		border: 0;
		font-size: 28px;
		color: #d0d0d0;
		font-family: Inter;
		cursor: pointer;
		margin-left: 5px;
		margin-right: 0;

		// &:last-child {
		// margin: 0;
		// }
			
			&.out-link {
				padding-top: 4px;
			}
		}
  }

}
.ant-select-dropdown {
	background-color: ~"var(--popover-bg)";

	.ant-select-item {
		background-color: ~"var(--popover-bg)";
		color: ~"var(--popover-text)";
	}
}

.ant-drawer-body {
	background-color: ~"var(--card-bg)";
}

.ant-divider {
	border-color: ~"var(--divider)";
}

.ant-popover-arrow-content {
	--antd-arrow-background-color: ~"var(--popover-bg)";
}
.anticon-close {
	color: ~"var(--black-text)";
}
.ant-spin-container > .ant-row {
	margin: 0 !important;
}
.date-card {
	padding: 10px 8px 0;

	.date {
		height: 30px;
		text-align: left;

		span {
			display: block;
			width: 100px;
			height: 100%;
			text-align: center;
			background: ~"var(--gradient-bg)";
			font-size: 16px;
			font-family: Inter;
			color: ~"var(--black-text)";
			line-height: 32px;
			border-radius: 12px 12px 0px 0px;
		}
	}
	.line {
		height: 1px;
		margin-bottom: 20px;
		background: ~"var(--gradient-bg)";
	}
	
}
.end-space-tip-container{
	margin: 130px auto;
	.end-title{
		height: 24px;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: var(--list-item-text);
		line-height: 25px;
		margin-bottom: 20px;

	}
	.end-detail{
		height: 40px;
		font-size: 14px;
		font-family: Inter-Regular, Inter;
		font-weight: 400;
		color: var(--white-text);
		line-height: 17px;
	}
	.go-detail-button{
		display: block;
		margin: 20px auto;
		height: 36px;
		background: #7269ef;
		border-radius: 8px;
		border:none;
	}
}
@primary-color: #1890ff;