.home-container {
	max-width: 1440px;
	min-height: 100vh;
	margin: 0 auto;
	padding: 24px 40px;
	background-color: ~"var(--main-bg)";
	// overflow: hidden;
	color: #fff;

	.host-info-container {
		width: 100%;
		display: flex;
		align-items: flex-start;

		.host-info-card {
			width: 312px;
			border-radius: 16px;
			background-color: ~'var(--host-info-bg)';

			.host-info-card-top {
				position: relative;
				display: flex;
				align-items: center;
				padding: 12px 16px;
				cursor: pointer;
		
				.info {
					width: calc(100% - 76px);
					margin-left: 16px;
		
					.name, .account {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 18px;
						font-family: Inter;
						color: ~'var(--list-item-text)';
					}
					.account {
						font-size: 14px;
						font-family: Inter;
						// opacity: 0.5;

						span {
							display: flex;
							align-items: center;
						}
					}
					.name span {
						width: 100%;
						text-align: left;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		
			.host-avatar {
				position: relative;
				width: 60px;
				height: 60px;
				border: 2px solid #fff;
				border-radius: 50%;
		
				&.live {
					border: 2px solid rgb(55,59,255);
				}
		
				div {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
				.follow-state {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 18px;
					height: 18px;
					left: 41px;
					top: 41px;
					border: 1px solid #1E262C;
					transform: none;
					border-radius: 50%;
					background-color: #7269EF;
				}
			}

			.follow-btn {
				width: 100%;
				border-bottom: 1px solid ~'var(--host-info-divider)';
				
				.btn {
					width: 280px;
					height: 36px;
					margin: 8px 16px 20px;
					background: #7269EF;
					border-radius: 18px;
					font-size: 14px;
					font-family: Inter;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 22px;

					&.followed {
						display: flex;
						justify-content: center;
						
						span {
							display: flex;
							align-items: center;
						}
						.icon {
							margin-right: 8px;
						}
					}

					&.following {
						background: ~"var(--card-bottom-bg)";
						color: ~"var(--card-button-text)";
					}
					&.unfollow {
						background: #D64E6D;
					}
				}
			}

			.info-count {
				padding: 18px 16px;

				.item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 8px 0;

					.label, .value {
						font-size: 14px;
						font-family: Inter;
						color: ~'var(--list-item-text)';
						line-height: 17px;
					}
					.label {
						opacity: 0.5;
					}
				}
			}

			.live-space {
				padding: 6px 12px;
				border-top: 1px solid ~'var(--host-info-divider)';

				.card-bottom {
					padding-top: 12px;
					background-color: ~'var(--right-text-background)';
				}
			}
		}

		.host-space-list {
			width: calc(100% - 342px);
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;
	
			.ant-spin-container,
			.ant-spin-nested-loading {
				width: 100%;
			}
	
			#scrollableDiv {
				.ant-spin-container,
				.ant-spin-nested-loading {
					border-radius: 16px 16px 0px 0px;
					overflow: hidden;
				}
			}
	
			.filter {
				width: 100%;
				.filter-top {
					display: flex;
					justify-content: flex-end;
	
					.state {
						display: flex;
						align-items: center;
					}
	
					.state-tag {
						display: flex;
						align-items: center;
						height: 40px;
						padding: 0 20px;
						background: ~"var(--label-bg)";
						border-radius: 8px;
						border: 1px solid ~"var(--label-border)";
						font-size: 14px;
						font-family: Inter;
						color: ~"var(--label-text)";
						line-height: 38px;
						transition: all 0.5s;
	
						.anticon-fire {
							padding-right: 3px;
						}
	
						&:hover {
							background: ~"var(--label-hover-bg)";
							border: 1px solid #6e6e6e;
						}
	
						&.ant-tag-checkable-checked {
							background: ~"var(--label-active-bg)";
							border-color: #d0d0d0;
							color: ~"var(--black-text)";
	
							&.live {
								border: 0;
								color: #fff;
								background: linear-gradient(
									225deg,
									#995dfb 0%,
									#373bff 100%
								);
							}
						}
						img {
							margin-left: 3px;
						}
					}
					.filter-select {
						.ant-select-selector {
							width: 130px;
							height: 40px;
							background: ~"var(--label-bg)";
							border-radius: 0px 8px 8px 0px;
							color: ~"var(--label-text)";
							border: 1px solid ~"var(--label-border)";
	
							.ant-select-selection-item {
								line-height: 38px;
							}
							&:hover {
								background: ~"var(--label-hover-bg)";
								border: 1px solid #6e6e6e;
							}
						}
						.ant-select-arrow {
							color: ~"var(--select-arrow)";
						}
	
						&:first-child {
							.ant-select-selector {
								border-radius: 8px 8px 8px 8px;
							}
						}
					}
					.ant-select-single.ant-select-open .ant-select-selection-item {
						color: ~"var(--label-text)";
					}
					.lang-layout {
						display: flex;
						width: 250px;
	
						.layout {
							display: flex;
							margin-left: auto;
						}
						.layout-tag {
							padding: 0 8px;
							line-height: 35px;
							transition: all 0.5s;
	
							&:last-child {
								margin: 0;
							}
						}
					}
				}
				.filter-bottom {
					.label-tag {
						position: relative;
						height: 25px;
						padding: 0 15px;
						margin-bottom: 10px;
						background: ~"var(--label-bg)";
						border-radius: 13px;
						border: 1px solid ~"var(--label-border)";
						font-size: 14px;
						font-family: Inter;
						color: ~"var(--label-text)";
						line-height: 23px;
						transition: all 0.5s;
	
						&:hover {
							background: ~"var(--label-hover-bg)";
							border: 1px solid #6e6e6e;
						}
	
						&.ant-tag-checkable-checked {
							background: ~"var(--label-active-bg)";
							border-color: #d0d0d0;
							color: ~"var(--black-text)";
						}
	
						.cancel-label {
							position: absolute;
							top: -7px;
							right: -7px;
							width: 14px;
							height: 14px;
							cursor: pointer;
						}
					}
				}
			}
	
			.ant-form-inline .ant-form-item {
				width: 200px;
			}
	
			.home-list {
				margin-top: 20px;
			}
		}
	}
}

.ant-divider {
	border-color: ~"var(--divider)";
}

.anticon-close {
	color: ~"var(--black-text)";
}
.ant-spin-container > .ant-row {
	margin: 0 !important;
}
@media (max-width: 700px) {
	.home-container {
		width: 100vw;
		padding: 20px;

		.host-info-container {
			flex-direction: column;
			align-items: center;

			.host-info-card, .host-space-list {
				width: calc(100vw - 40px);
			}
			.host-space-list {
				margin-left: 0;

				.filter {
					display: none;
				}
			}
		}
	}
}

@primary-color: #1890ff;